import { ElementCraftingPreview } from '../../api/elements/preview-element-set-crafting';
import { Col, Image, Row } from 'react-bootstrap';
import styles from './Crafting.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { getThumbnailUrl } from '../../api/image/get-image';
import commonStyles from '../../desktop-view/Common.module.scss';
import { ImageZoomModal } from '../ImageZoomModal';

export const PreviewCraftingElementTable = (prop: {
  elementList: ElementCraftingPreview[];
  elementIdToScroll?: string;
  onGoToEnd?: () => void;
}) => {
  const refToScroll = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (prop.elementIdToScroll && refToScroll.current) {
      refToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [prop.elementIdToScroll]);

  return (
    <>
      <TableHeader />
      {prop.elementList.map(it => {
        if (it.id === prop.elementIdToScroll) {
          return (
            <ElementRow
              ref={refToScroll}
              onGoToEnd={prop.onGoToEnd}
              elementPreview={it}
            />
          );
        } else {
          return <ElementRow elementPreview={it} />;
        }
      })}
    </>
  );
};

const TableHeader = () => {
  return (
    <Row className={'border-bottom border-3 pb-2'}>
      <Col
        sm={'auto'}
        className={'h-100 d-flex justify-content-end'}>
        <div className={`vr ${styles.invisibleVerticalLine}`}></div>
      </Col>
      <Col></Col>
      <Col>Nazwa</Col>
      <Col>SKU</Col>
      <Col>Kategoria</Col>
      <Col>Ilość posiadana</Col>
      <Col>Ilość potrzebna</Col>
      <Col>Ilość końcowa</Col>
    </Row>
  );
};

const ElementRow = React.forwardRef<
  HTMLDivElement,
  {
    elementPreview: ElementCraftingPreview;
    onGoToEnd?: (() => void) | undefined;
  }
>((props, ref) => {
  const { elementPreview, onGoToEnd } = props;

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        onGoToEnd!!();
      }, 1000); // Remove highlight after 1 second
    }
  }, [ref, onGoToEnd]);

  return (
    <Row
      ref={ref}
      className={`align-items-center pt-2 pb-2 border-bottom border-1 ${isHighlighted ? styles.highlight : ''}`}>
      <Col
        sm={'auto'}
        className={'h-100 d-flex justify-content-end'}>
        <div className={`vr ${styles.invisibleVerticalLine}`}></div>
      </Col>
      <Col className={'d-flex justify-content-center'}>
        <Image
          src={getThumbnailUrl(elementPreview.imageId)}
          fluid
          className={commonStyles.zoomableImage}
          onClick={() => handleImageClick(elementPreview.imageId)}
          style={{ width: 50, height: 50 }}
        />
      </Col>
      <Col className={'text-break'}>{elementPreview.name}</Col>
      <Col className={'text-break'}>{elementPreview.stockKeepingUnit}</Col>
      <Col className={'text-break'}>{elementPreview.category}</Col>
      <Col className={'text-break'}>{elementPreview.oldQuantity}</Col>
      <Col className={'text-break'}>{elementPreview.requiredQuantity}</Col>
      <Col
        className={`text-break ${elementPreview.newQuantity >= 0 ? styles.greenText : styles.redText}`}>
        {elementPreview.newQuantity}
      </Col>
      <ImageZoomModal
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        imageId={selectedImage}
      />
    </Row>
  );
});
