import {
  ElementCraftingPreview,
  ElementSetCraftingPreview,
} from '../../../api/elements/preview-element-set-crafting';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion, Col, Form, Image, Row } from 'react-bootstrap';
import styles from '../Crafting.module.scss';
import { debounce } from 'lodash';
import { getThumbnailUrl } from '../../../api/image/get-image';
import commonStyles from '../../../mobile-view/Common.module.scss';
import { ImageZoomModal } from '../../ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { AccordionToggle } from '../../AccordionToggle';
import { CraftingMobileItemHeader } from './generic/CraftingMobileItemHeader';
import { useImageModal } from '../../../hook/useImageModal';

export const PreviewCraftingElementSetMobileTable = (props: {
  elementSetList: ElementSetCraftingPreview[];
  onGoToClick?: (id: string, activeTab: string) => void;
  onQuantityChange?: (quantity: number) => void;
  isCraftingRoot: boolean;
  elementIdToScroll?: string;
  onGoToEnd?: () => void;
}) => {
  const {
    elementSetList,
    onGoToClick,
    onQuantityChange,
    isCraftingRoot,
    elementIdToScroll,
    onGoToEnd,
  } = props;

  const refToScroll = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementIdToScroll && refToScroll.current) {
      refToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [elementIdToScroll]);

  return (
    <>
      {isCraftingRoot && elementSetList.length === 1 ? (
        <Accordion
          key={elementSetList[0].id}
          alwaysOpen>
          <ElementSetCraftingRoot
            elementSetPreview={elementSetList[0]}
            onQuantityChange={onQuantityChange!!}
          />
        </Accordion>
      ) : (
        elementSetList.map(elementSet => {
          if (elementSet.id === elementIdToScroll) {
            return (
              <Accordion
                key={elementSet.id}
                alwaysOpen>
                <ElementSetRow
                  ref={refToScroll}
                  elementSetPreview={elementSet}
                  onGoToClick={onGoToClick!!}
                  onGoToEnd={onGoToEnd}
                />
              </Accordion>
            );
          } else {
            return (
              <Accordion
                key={elementSet.id}
                alwaysOpen>
                <ElementSetRow
                  elementSetPreview={elementSet}
                  onGoToClick={onGoToClick!!}
                />
              </Accordion>
            );
          }
        })
      )}
    </>
  );
};

interface ElementSetCraftingRootProps {
  elementSetPreview: ElementSetCraftingPreview;
  onQuantityChange: (quantity: number) => void;
}

const ElementSetCraftingRoot = (props: ElementSetCraftingRootProps) => {
  const { elementSetPreview, onQuantityChange } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [quantity, setQuantity] = useState<number>(
    elementSetPreview.requiredQuantity
  );
  const setCraftQuantityDebounced = useMemo(() => {
    return debounce(onQuantityChange, 500);
  }, [onQuantityChange]);

  const handleCraftQuantityChange = useCallback(
    (event: any) => {
      const newCraftQuantity =
        event.target.value !== '' && event.target.validity.valid
          ? parseInt(event.target.value)
          : 0;
      setQuantity(newCraftQuantity);
    },
    [setQuantity]
  );

  useEffect(() => {
    setCraftQuantityDebounced(quantity);
  }, [quantity, setCraftQuantityDebounced]);

  return (
    <>
      <Row className={'pt-3 pb-3'}>
        <Col
          sm={'auto'}
          className={'border-bottom border-3'}></Col>
      </Row>
      <Row>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(elementSetPreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => handleImageClick(elementSetPreview.imageId)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={selectedImage}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={`text-break ${commonStyles.smallBoldText}`}>
              {elementSetPreview.name}
            </div>
            <div className={`text-break ${commonStyles.smallText}`}>
              {elementSetPreview.stockKeepingUnit}
            </div>
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {elementSetPreview.oldQuantity}
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            <Form.Control
              className={'w-100  p-0'}
              type='number'
              defaultValue={quantity}
              min='0'
              onChange={handleCraftQuantityChange}
            />
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {elementSetPreview.newQuantity}
          </Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center ps-1'}>
            <AccordionToggle
              eventKey={elementSetPreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={elementSetPreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div className={'text-break'}>{elementSetPreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div className={'text-break'}>
                    {elementSetPreview.stockKeepingUnit}
                  </div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div className={'text-break'}>
                    {elementSetPreview.category}
                  </div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Tak</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div className={'text-break'}>
                    {elementSetPreview.oldQuantity}
                  </div>
                </Col>
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
      {elementSetPreview.subItemList.map(it => (
        <Accordion
          key={it.id}
          alwaysOpen>
          <SubItemRow element={it} />
        </Accordion>
      ))}
    </>
  );
};

const ElementSetRow = React.forwardRef<
  HTMLDivElement,
  {
    elementSetPreview: ElementSetCraftingPreview;
    onGoToClick: (id: string, activeTab: string) => void;
    onGoToEnd?: (() => void) | undefined;
  }
>((props, ref) => {
  const { elementSetPreview, onGoToClick, onGoToEnd } = props;

  const { showImageModal, setShowImageModal } = useImageModal();
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        onGoToEnd!!();
      }, 1000); // Remove highlight after 1 second
    }
  }, [ref, onGoToEnd]);

  return (
    <>
      <Row
        ref={ref}
        className={`${isHighlighted ? styles.highlight : ''}`}>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 align-items-center border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(elementSetPreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => setShowImageModal(true)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={elementSetPreview.imageId}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={`text-break ${commonStyles.smallBoldText}`}>
              {elementSetPreview.name}
            </div>
            <div className={`text-break ${commonStyles.smallText}`}>
              {elementSetPreview.stockKeepingUnit}
            </div>
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {elementSetPreview.oldQuantity}{' '}
            {elementSetPreview.craftedQuantity !== 0 && (
              <label className={styles.greenText}>
                +{elementSetPreview.craftedQuantity}
              </label>
            )}
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {elementSetPreview.requiredQuantity}
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            <div
              className={
                elementSetPreview.newQuantity >= 0
                  ? styles.greenText
                  : styles.redText
              }>
              {elementSetPreview.newQuantity}
            </div>
          </Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center ps-1'}>
            <AccordionToggle
              eventKey={elementSetPreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={elementSetPreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div>{elementSetPreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div>{elementSetPreview.stockKeepingUnit}</div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div>{elementSetPreview.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Tak</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div>{elementSetPreview.oldQuantity}</div>
                </Col>
                <Col xs={2} />
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
      {elementSetPreview.subItemList.map(it => (
        <SubItemRow
          key={it.id}
          element={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
});

const SubItemRow = (props: {
  element: ElementCraftingPreview;
  handleGoToButtonClick?: (id: string, activeTab: string) => void;
}) => {
  const { element, handleGoToButtonClick } = props;
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <Row>
      <div className={styles.mobileVerticalLineDiv}>
        <div className={`vr ${styles.verticalLine}`}></div>
      </div>
      <div className={styles.mobileCraftingRowContentDiv}>
        <Row
          className={'pt-2 pb-2 align-items-center'}
          key={element.stockKeepingUnit}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(element.imageId)}
              fluid
              className={commonStyles.thumbnailSmallImage}
              onClick={() => setShowImageModal(true)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={element.imageId}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={`text-break ${commonStyles.smallBoldText}`}>
              {element.name}
            </div>
            <div className={`text-break ${commonStyles.smallText}`}>
              {element.stockKeepingUnit}
            </div>
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {element.oldQuantity}
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {element.requiredQuantity}
          </Col>
          <Col
            xs={2}
            className={`text-break ${element.newQuantity >= 0 ? styles.greenText : styles.redText}`}>
            {element.newQuantity}
          </Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center ps-1'}>
            <AccordionToggle
              eventKey={element.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={element.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div>{element.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div>{element.stockKeepingUnit}</div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div>{element.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Nie</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div>{element.oldQuantity}</div>
                </Col>
                {handleGoToButtonClick && (
                  <Col xs={2}>
                    <Row className={'justify-content-around'}>
                      <Col xs={2}>
                        <FontAwesomeIcon
                          icon={faArrowCircleRight}
                          size={'2x'}
                          className={`${commonStyles.iconPrimary}`}
                          onClick={() =>
                            handleGoToButtonClick(element.id, 'elements')
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
        <Row>
          <Col className={styles.horizontalLine}></Col>
        </Row>
      </div>
    </Row>
  );
};
