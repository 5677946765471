import { useSetTitle } from '../../../context/TitleContext';
import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { FormEvent, useEffect, useState } from 'react';
import { FormInput } from '../../../components/form/FormInput';
import { roleMappings, UserRole } from '../../../api/auth/token-content';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { FormSelect } from '../../../components/form/FormSelect';
import { useGetUser } from '../../../api/users/get-user';
import { useUpdateUser } from '../../../api/users/update-user';

export function EditUser() {
  useSetTitle('Ustawienia - Edytuj użytkownika');
  const navigate = useNavigate();
  const { userId } = useParams();
  const [getUserRequest] = useState({ userId: userId! });
  const [response] = useGetUser(getUserRequest);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<UserRole>(UserRole.BLANK);
  const [validated, setValidated] = useState(false);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);

  useEffect(() => {
    setFirstName(response.data?.firstName ?? '');
    setLastName(response.data?.lastName ?? '');
    setEmail(response.data?.email ?? '');
    if (response.data?.role !== UserRole.SYSTEM_ADMIN) {
      setUserRoles(
        Object.values(UserRole).filter(
          role => role !== UserRole.BLANK && role !== UserRole.SYSTEM_ADMIN
        )
      );
    } else {
      setUserRoles(
        Object.values(UserRole).filter(role => role !== UserRole.BLANK)
      );
    }
    setRole(response.data?.role ?? UserRole.BLANK);
  }, [response, setUserRoles]);
  const { updateUser } = useUpdateUser();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    updateUser({
      userId: userId!,
      firstName,
      lastName,
      role,
    }).then(response => {
      if (!response.isLoading && !response.error) {
        navigate(-1);
      }
    });
  };
  const onRoleChange = (selectedRole: string) => {
    const roleValue = selectedRole as UserRole;
    setRole(roleValue);
  };

  return (
    <Row className='justify-content-sm-center align-items-sm-center mt-5'>
      <Col sm='5'>
        <Form
          className='w-100'
          noValidate
          validated={validated}
          onSubmit={handleSubmit}>
          <FormInput
            value={firstName}
            setValue={setFirstName}
            controlId={'firstName'}
            label={'Imię'}
            errorText={'Imię jest wymagane'}
          />
          <FormInput
            value={lastName}
            setValue={setLastName}
            controlId={'lastName'}
            label={'Nazwisko'}
            errorText={'Nazwisko jest wymagane'}
          />
          <FormInput
            value={email}
            setValue={setEmail}
            controlId={'email'}
            label={'Email'}
            errorText={'Email jest wymagany'}
            disabled={true}
          />
          <FormSelect
            controlId='role'
            label='Rola'
            required={true}
            onChange={onRoleChange}
            emptyValueLabel='Wybierz rolę...'
            values={userRoles}
            valueLabelMappings={roleMappings()}
            errorText='Rola jest wymagana'
            value={role}
            disabled={role === UserRole.SYSTEM_ADMIN}
          />
          <EditUserFormButtons />
        </Form>
      </Col>
    </Row>
  );
}

const EditUserFormButtons = () => {
  const navigate = useNavigate();

  return (
    <Row
      className='pt-5 justify-content-sm-between align-items-sm-center'
      style={{ backgroundColor: 'white', position: 'sticky', bottom: '0' }}>
      <Col sm={4}>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-100 mb-2'>
          <FontAwesomeIcon icon={faXmark} />
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col sm={4}>
        <Button
          variant='primary'
          className='w-100 mb-2'
          type='submit'>
          <FontAwesomeIcon icon={faCheck} />
          <span className='m-2'>Zapisz</span>
        </Button>
      </Col>
    </Row>
  );
};
