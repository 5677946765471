import {
  CustomColumn,
  CustomTable,
} from '../../../components/table/CustomTable';
import { OperationTypeRenderer } from '../../../components/table/renderers/OperationTypeRenderer';
import { DateWithMinutesRenderer } from '../../../components/table/renderers/DateRenderer';
import { useProductsHistory } from '../../../api/products/products-history';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProductsHistoryFilters } from '../../../components/products/ProductsHistoryFilters';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import CustomPagination from '../../../components/CustomPagination';

const columns: CustomColumn[] = [
  {
    name: 'SKU',
    field: 'stockKeepingUnit',
    className: 'col-sm-2',
  },
  {
    name: 'E-Mail',
    field: 'email',
    className: 'col-sm-2',
  },
  {
    name: 'Imie',
    field: 'firstName',
    className: 'col-sm-1',
  },
  {
    name: 'Nazwisko',
    field: 'lastName',
    className: 'col-sm-2',
  },
  {
    name: 'Typ Akcji',
    field: 'operationType',
    className: 'col-sm-2',
    renderer: OperationTypeRenderer,
  },
  {
    name: 'Data',
    field: 'creationTimestamp',
    className: 'col-sm-1',
    renderer: DateWithMinutesRenderer,
  },
  {
    name: 'Opis',
    field: 'description',
    className: 'col-sm-1',
  },
];

export function ProductsHistory() {
  const {
    productsHistoryResponse,
    isLoading,
    getProductsHistoryRequest,
    setGetProductsHistoryRequest,
    handlePaginationChange,
  } = useProductsHistory();

  const isProductsHistoryEmpty =
    productsHistoryResponse.productHistory.length === 0;

  return (
    <>
      <Row className='align-items-sm-center'>
        <Col sm='10'>
          <ProductsHistoryFilters
            productsHistoryRequest={getProductsHistoryRequest}
            setProductsHistoryRequest={setGetProductsHistoryRequest}
          />
        </Col>
      </Row>
      {isLoading && <CenteredSpinner />}
      {!isLoading && (
        <CustomTable
          columns={columns}
          data={productsHistoryResponse.productHistory}
          emptyListMessage='Brak wpisów w historii produktów.'
          refetch={() =>
            setGetProductsHistoryRequest({ ...getProductsHistoryRequest })
          }
        />
      )}
      {!isProductsHistoryEmpty && (
        <CustomPagination
          pageNumber={productsHistoryResponse.page.pageNumber + 1}
          elementsPerPage={productsHistoryResponse.page.pageSize}
          items={productsHistoryResponse.productHistory}
          totalPages={productsHistoryResponse.page.pageCount}
          onPaginationParamsChange={(pageNumber, elementsPerPage) =>
            handlePaginationChange(pageNumber, elementsPerPage)
          }
        />
      )}
    </>
  );
}
