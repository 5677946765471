import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Col, Form, Image, Row } from 'react-bootstrap';
import styles from '../Crafting.module.scss';
import { debounce } from 'lodash';
import { getThumbnailUrl } from '../../../api/image/get-image';
import commonStyles from '../../../mobile-view/Common.module.scss';
import { ImageZoomModal } from '../../ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import {
  ProductCraftingPreview,
  ProductSubItemCraftingPreview,
} from '../../../api/elements/preview-product-crafting';
import { AccordionToggle } from '../../AccordionToggle';
import { CraftingMobileItemHeader } from './generic/CraftingMobileItemHeader';
import { useImageModal } from '../../../hook/useImageModal';

export const PreviewCraftingProductMobileTable = (props: {
  productList: ProductCraftingPreview[];
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange?: (quantity: number) => void;
  isCraftingRoot: boolean;
}) => {
  const { productList, onGoToClick, onQuantityChange, isCraftingRoot } = props;

  return (
    <>
      {isCraftingRoot && productList.length === 1 ? (
        <Accordion
          key={productList[0].id}
          alwaysOpen>
          <ProductCraftingRoot
            productPreview={productList[0]}
            onGoToClick={onGoToClick}
            onQuantityChange={onQuantityChange!!}
          />
        </Accordion>
      ) : (
        productList.map(product => (
          <ProductRow
            productPreview={product}
            onGoToClick={onGoToClick}
          />
        ))
      )}
    </>
  );
};

interface ProductCraftingRootProps {
  productPreview: ProductCraftingPreview;
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange: (quantity: number) => void;
}

const ProductCraftingRoot = (props: ProductCraftingRootProps) => {
  const { productPreview, onGoToClick, onQuantityChange } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [quantity, setQuantity] = useState<number>(
    productPreview.requiredQuantity
  );
  const setCraftQuantityDebounced = useMemo(() => {
    return debounce(onQuantityChange, 500);
  }, [onQuantityChange]);

  const handleCraftQuantityChange = useCallback(
    (event: any) => {
      const newCraftQuantity =
        event.target.value !== '' && event.target.validity.valid
          ? parseInt(event.target.value)
          : 0;
      setQuantity(newCraftQuantity);
    },
    [setQuantity]
  );

  useEffect(() => {
    setCraftQuantityDebounced(quantity);
  }, [quantity, setCraftQuantityDebounced]);

  return (
    <>
      <Row>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(productPreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => handleImageClick(productPreview.imageId)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={selectedImage}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={`text-break ${commonStyles.smallBoldText}`}>
              {productPreview.name}
            </div>
            <div className={`text-break ${commonStyles.smallText}`}>
              {productPreview.stockKeepingUnit}
            </div>
          </Col>
          <Col xs={2}>{'-'}</Col>
          <Col
            xs={2}
            className={'text-break'}>
            <Form.Control
              className={'w-100  p-0'}
              type='number'
              defaultValue={quantity}
              min='0'
              onChange={handleCraftQuantityChange}
            />
          </Col>
          <Col xs={2}>{'-'}</Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center'}>
            <AccordionToggle
              eventKey={productPreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={productPreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div className={'text-break'}>{productPreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div className={'text-break'}>
                    {productPreview.stockKeepingUnit}
                  </div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div className={'text-break'}>{productPreview.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>EAN</div>
                  <div className={'text-break'}>
                    {productPreview.europeanArticleNumber}
                  </div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div className={'text-break'}>
                    {productPreview.oldQuantity}
                  </div>
                </Col>
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
      {productPreview.subiItemList.map(it => (
        <Accordion
          key={it.id}
          alwaysOpen>
          <SubItemRow
            subItem={it}
            handleGoToButtonClick={onGoToClick}
          />
        </Accordion>
      ))}
    </>
  );
};

const ProductRow = (props: {
  productPreview: ProductCraftingPreview;
  onGoToClick: (id: string, activeTab: string) => void;
}) => {
  const { productPreview, onGoToClick } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();

  return (
    <>
      <Row>
        <CraftingMobileItemHeader />
        <div className={styles.mobileVerticalLineDiv} />
        <Row
          className={`pb-2 px-0 align-items-center border-bottom border-1 ${styles.mobileCraftingRowContentDiv}`}>
          <Col
            xs={2}
            className={'p-0'}>
            <Image
              src={getThumbnailUrl(productPreview.imageId)}
              fluid
              className={`${commonStyles.thumbnailSmallImage}`}
              onClick={() => handleImageClick(productPreview.imageId)}
            />
            <ImageZoomModal
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              imageId={selectedImage}
            />
          </Col>
          <Col
            xs={3}
            className={'p-0'}>
            <div className={`text-break ${commonStyles.smallBoldText}`}>
              {productPreview.name}
            </div>
            <div className={`text-break ${commonStyles.smallText}`}>
              {productPreview.stockKeepingUnit}
            </div>
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {productPreview.oldQuantity}{' '}
            {productPreview.craftedQuantity !== 0 && (
              <label className={styles.greenText}>
                +{productPreview.craftedQuantity}
              </label>
            )}
          </Col>
          <Col
            xs={2}
            className={'text-break'}>
            {productPreview.requiredQuantity}
          </Col>
          <Col xs={2}>
            <div
              className={`text-break ${
                productPreview.newQuantity >= 0
                  ? styles.greenText
                  : styles.redText
              }`}>
              {productPreview.newQuantity}
            </div>
          </Col>
          <Col
            xs={1}
            className={'d-flex justify-content-center'}>
            <AccordionToggle
              eventKey={productPreview.id}
              iconSize='lg'
            />
          </Col>
          <Accordion.Collapse
            className={'ps-0'}
            eventKey={productPreview.id}>
            <>
              <Row className={'mt-3'}>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>Nazwa</div>
                  <div className={'text-break'}>{productPreview.name}</div>
                </Col>
                <Col xs={5}>
                  <div className={commonStyles.secondaryText}>SKU</div>
                  <div className={'text-break'}>
                    {productPreview.stockKeepingUnit}
                  </div>
                </Col>
              </Row>
              <Row className={'mt-3 pe-2'}>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Kategoria</div>
                  <div className={'text-break'}>{productPreview.category}</div>
                </Col>
                <Col xs={3}>
                  <div className={commonStyles.secondaryText}>Komplet</div>
                  <div>Tak</div>
                </Col>
                <Col xs={4}>
                  <div className={commonStyles.secondaryText}>W magazynie</div>
                  <div className={'text-break'}>
                    {productPreview.oldQuantity}
                  </div>
                </Col>
                {onGoToClick && (
                  <Col xs={2}>
                    <Row className={'justify-content-around'}>
                      <Col xs={2}>
                        <FontAwesomeIcon
                          icon={faArrowCircleRight}
                          size={'2x'}
                          className={`${commonStyles.iconPrimary}`}
                          onClick={() =>
                            onGoToClick(productPreview.id, 'products')
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </>
          </Accordion.Collapse>
        </Row>
      </Row>
      {productPreview.subiItemList.map(it => (
        <SubItemRow
          key={it.id}
          subItem={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
};

const SubItemRow = (props: {
  subItem: ProductSubItemCraftingPreview;
  handleGoToButtonClick: (id: string, activeTab: string) => void;
}) => {
  const { subItem, handleGoToButtonClick } = props;
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };

  return (
    <>
      <Row>
        <div className={styles.mobileVerticalLineDiv}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </div>
        <div className={styles.mobileCraftingRowContentDiv}>
          <Row
            className={'pt-2 pb-2 align-items-center'}
            key={subItem.stockKeepingUnit}>
            <Col
              xs={2}
              className={'p-0'}>
              <Image
                src={getThumbnailUrl(subItem.imageId)}
                fluid
                className={commonStyles.thumbnailSmallImage}
                onClick={() => handleImageClick(subItem.imageId)}
              />
              <ImageZoomModal
                showImageModal={showImageModal}
                setShowImageModal={setShowImageModal}
                imageId={selectedImage}
              />
            </Col>
            <Col
              xs={3}
              className={'p-0'}>
              <div className={`text-break ${commonStyles.smallBoldText}`}>
                {subItem.name}
              </div>
              <div className={`text-break ${commonStyles.smallText}`}>
                {subItem.stockKeepingUnit}
              </div>
            </Col>
            <Col
              xs={2}
              className={'text-break'}>
              {subItem.oldQuantity}{' '}
              {subItem.craftedQuantity !== 0 && (
                <label className={styles.greenText}>
                  +{subItem.craftedQuantity}
                </label>
              )}
            </Col>
            <Col
              xs={2}
              className={'text-break'}>
              {subItem.requiredQuantity}
            </Col>
            <Col
              xs={2}
              className={`text-break ${subItem.newQuantity >= 0 ? styles.greenText : styles.redText}`}>
              {subItem.newQuantity}
            </Col>
            <Col
              xs={1}
              className={'d-flex justify-content-center'}>
              <AccordionToggle
                eventKey={subItem.id}
                iconSize='lg'
              />
            </Col>
            <Accordion.Collapse
              className={'ps-0'}
              eventKey={subItem.id}>
              <>
                <Row className={'mt-3'}>
                  <Col xs={5}>
                    <div className={commonStyles.secondaryText}>Nazwa</div>
                    <div className={'text-break'}>{subItem.name}</div>
                  </Col>
                  <Col xs={5}>
                    <div className={commonStyles.secondaryText}>SKU</div>
                    <div className={'text-break'}>
                      {subItem.stockKeepingUnit}
                    </div>
                  </Col>
                </Row>
                <Row className={'mt-3 pe-2'}>
                  <Col xs={3}>
                    <div className={commonStyles.secondaryText}>Kategoria</div>
                    <div className={'text-break'}>{subItem.category}</div>
                  </Col>
                  <Col xs={3}>
                    <div className={commonStyles.secondaryText}>Komplet</div>
                    <div>Tak</div>
                  </Col>
                  <Col xs={4}>
                    <div className={commonStyles.secondaryText}>
                      W magazynie
                    </div>
                    <div className={'text-break'}>{subItem.oldQuantity}</div>
                  </Col>
                  {handleGoToButtonClick && (
                    <Col xs={2}>
                      <Row className={'justify-content-around'}>
                        <Col xs={2}>
                          <FontAwesomeIcon
                            icon={faArrowCircleRight}
                            size={'2x'}
                            className={`${commonStyles.iconPrimary}`}
                            onClick={() =>
                              handleGoToButtonClick(subItem.id, 'modules')
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </>
            </Accordion.Collapse>
          </Row>
          <Row>
            <Col className={styles.horizontalLine}></Col>
          </Row>
        </div>
      </Row>
    </>
  );
};
