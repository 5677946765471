import {
  ElementCraftingPreview,
  ElementSetCraftingPreview,
} from '../../api/elements/preview-element-set-crafting';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import styles from './Crafting.module.scss';
import { debounce } from 'lodash';
import { getThumbnailUrl } from '../../api/image/get-image';
import commonStyles from '../../desktop-view/Common.module.scss';
import { ImageZoomModal } from '../ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useImageModal } from '../../hook/useImageModal';

export const PreviewCraftingElementSetTable = (props: {
  elementSetList: ElementSetCraftingPreview[];
  onGoToClick: (id: string) => void;
  onQuantityChange?: (quantity: number) => void;
  isCraftingRoot: boolean;
  elementIdToScroll?: string;
  onGoToEnd?: () => void;
}) => {
  const {
    elementSetList,
    onGoToClick,
    onQuantityChange,
    isCraftingRoot,
    elementIdToScroll,
    onGoToEnd,
  } = props;

  const refToScroll = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementIdToScroll && refToScroll.current) {
      refToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [elementIdToScroll]);

  return (
    <>
      <TableHeader />
      {isCraftingRoot && elementSetList.length === 1 ? (
        <ElementSetCraftingRoot
          elementSetPreview={elementSetList[0]}
          onGoToClick={onGoToClick}
          onQuantityChange={onQuantityChange!!}
        />
      ) : (
        elementSetList.map(elementSet => {
          if (elementSet.id === elementIdToScroll) {
            return (
              <ElementSetRow
                ref={refToScroll}
                elementSetPreview={elementSet}
                onGoToClick={onGoToClick}
                onGoToEnd={onGoToEnd}
              />
            );
          } else {
            return (
              <ElementSetRow
                elementSetPreview={elementSet}
                onGoToClick={onGoToClick}
              />
            );
          }
        })
      )}
    </>
  );
};

const TableHeader = () => {
  return (
    <Row className={'border-bottom border-3 pb-2'}>
      <Col
        sm={'auto'}
        className={'h-100 d-flex justify-content-end'}>
        <div className={`vr ${styles.invisibleVerticalLine}`}></div>
      </Col>
      <Col></Col>
      <Col>Nazwa</Col>
      <Col>SKU</Col>
      <Col>Kategoria</Col>
      <Col>Ilość posiadana</Col>
      <Col>Ilość potrzebna</Col>
      <Col>Ilość końcowa</Col>
      <Col></Col>
    </Row>
  );
};

const ElementSetCraftingRoot = (props: {
  elementSetPreview: ElementSetCraftingPreview;
  onGoToClick: (id: string) => void;
  onQuantityChange: (quantity: number) => void;
}) => {
  const { elementSetPreview, onGoToClick, onQuantityChange } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [quantity, setQuantity] = useState<number>(
    elementSetPreview.requiredQuantity
  );
  const setCraftQuantityDebounced = useMemo(() => {
    return debounce(onQuantityChange, 500);
  }, [onQuantityChange]);

  const handleCraftQuantityChange = useCallback(
    (event: any) => {
      const newCraftQuantity =
        event.target.value !== '' && event.target.validity.valid
          ? parseInt(event.target.value)
          : 0;
      setQuantity(newCraftQuantity);
    },
    [setQuantity]
  );

  useEffect(() => {
    setCraftQuantityDebounced(quantity);
  }, [quantity, setCraftQuantityDebounced]);

  return (
    <>
      <Row className={'align-items-center pt-2 pb-2 border-bottom border-1'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.invisibleVerticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center'}>
          <Image
            src={getThumbnailUrl(elementSetPreview.imageId)}
            fluid
            className={commonStyles.zoomableImage}
            onClick={() => handleImageClick(elementSetPreview.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>
        <Col className={'text-break'}>{elementSetPreview.name}</Col>
        <Col className={'text-break'}>{elementSetPreview.stockKeepingUnit}</Col>
        <Col className={'text-break'}>{elementSetPreview.category}</Col>
        <Col className={'text-break'}>{elementSetPreview.oldQuantity}</Col>
        <Col className={'text-break'}>
          <Form.Control
            className='w-50'
            type='number'
            defaultValue={quantity}
            min='0'
            onChange={handleCraftQuantityChange}
          />
        </Col>
        <Col className={'text-break'}>
          <td>{elementSetPreview.newQuantity}</td>
        </Col>
        <Col></Col>

        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      {elementSetPreview.subItemList.map(it => (
        <SubItemRow
          element={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
};

const ElementSetRow = React.forwardRef<
  HTMLDivElement,
  {
    elementSetPreview: ElementSetCraftingPreview;
    onGoToClick: (id: string) => void;
    onGoToEnd?: (() => void) | undefined;
  }
>((props, ref) => {
  const { elementSetPreview, onGoToClick, onGoToEnd } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        onGoToEnd!!();
      }, 1000); // Remove highlight after 1 second
    }
  }, [ref, onGoToEnd]);

  return (
    <>
      <Row
        ref={ref}
        className={`align-items-center pt-2 pb-2 border-bottom border-1 ${isHighlighted ? styles.highlight : ''}`}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.invisibleVerticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center'}>
          <Image
            src={getThumbnailUrl(elementSetPreview.imageId)}
            fluid
            className={commonStyles.zoomableImage}
            onClick={() => handleImageClick(elementSetPreview.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>
        <Col className={'text-break'}>{elementSetPreview.name}</Col>
        <Col className={'text-break'}>{elementSetPreview.stockKeepingUnit}</Col>
        <Col className={'text-break'}>{elementSetPreview.category}</Col>
        <Col className={'text-break'}>
          {elementSetPreview.oldQuantity}{' '}
          {elementSetPreview.craftedQuantity !== 0 && (
            <label className={styles.greenText}>
              +{elementSetPreview.craftedQuantity}
            </label>
          )}
        </Col>
        <Col className={'text-break'}>{elementSetPreview.requiredQuantity}</Col>
        <Col
          className={`text-break ${
            elementSetPreview.newQuantity >= 0
              ? styles.greenText
              : styles.redText
          }`}>
          {elementSetPreview.newQuantity}
        </Col>
        <Col></Col>

        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      {elementSetPreview.subItemList.map(it => (
        <SubItemRow
          element={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
});

const SubItemRow = (props: {
  element: ElementCraftingPreview;
  handleGoToButtonClick: (id: string) => void;
}) => {
  const { element, handleGoToButtonClick } = props;
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };

  return (
    <>
      <Row className={'align-items-center'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center pt-2 pb-2'}>
          <Image
            src={getThumbnailUrl(element.imageId)}
            fluid
            className={`${commonStyles.zoomableImage}`}
            onClick={() => handleImageClick(element.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>

        <Col className={'text-break'}>{element.name}</Col>
        <Col className={'text-break'}>{element.stockKeepingUnit}</Col>
        <Col className={'text-break'}>{element.category}</Col>
        <Col className={'text-break'}>{element.oldQuantity}</Col>
        <Col className={'text-break'}>{element.requiredQuantity}</Col>
        <Col
          className={`text-break ${element.newQuantity >= 0 ? styles.greenText : styles.redText}`}>
          {element.newQuantity}
        </Col>
        <Col>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            size={'xl'}
            className={`${commonStyles.iconPrimary} me-3`}
            onClick={() => handleGoToButtonClick(element.id)}
          />
        </Col>
        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      <Row className={'align-items-center'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </Col>
        <Col className={styles.horizontalLine}></Col>
      </Row>
    </>
  );
};
