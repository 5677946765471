import { ButtonGroup, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { Navigate, Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import { NavigationPath } from '../../data/constants';
import { authProvider } from '../../api/auth/auth';
import { TitleContext } from '../../context/TitleContext';
import { UserContextProvider } from '../../context/UserContextProvider';
import { clarityApi } from '../../api/clarity/clarity';
import { UserInfo } from '../../api/auth/user-info';
import { ErrorToast } from '../../components/ErrorToast';
import {
  ErrorContextProvider,
  useInitErrorContext,
} from '../../context/ErrorContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './RootPage.module.scss';
import { Menu } from './Menu';
import commonStyles from '../../desktop-view/Common.module.scss';

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {};
}

const IconToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.LegacyRef<HTMLDivElement>) => (
    <div ref={ref}>
      <FontAwesomeIcon
        icon={faUserCircle}
        size={'2x'}
        className={`${styles.icon}`}
        onClick={(e: React.MouseEvent<SVGSVGElement>) => {
          e.preventDefault();
          props.onClick(e);
        }}
      />
    </div>
  )
);

function RootPageContent() {
  const [title, setTitle] = useState('');
  const [showMenu, setShowMenu] = useState(false);

  const titleContext: TitleContext = { title, setTitle };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <React.StrictMode>
      <Container className={styles.container}>
        <div className={styles.header}>
          <Row className='pt-3 bg-white'>
            <Col
              className='d-flex justify-content-start'
              xs={6}>
              <FontAwesomeIcon
                icon={faBars}
                onClick={toggleMenu}
                size={'2x'}
                className={`${styles.icon}`}
              />
            </Col>
            <Col
              className='d-flex justify-content-end'
              xs={6}>
              <Dropdown
                className='d-inline-block'
                as={ButtonGroup}>
                <Dropdown.Toggle as={IconToggle}></Dropdown.Toggle>
                <Dropdown.Menu
                  className={`w-100 pt-0 pb-0 ${styles.customDropdown}`}>
                  <Dropdown.Item
                    className={`pt-3 pb-3 ${commonStyles.customHover}`}
                    eventKey='1'
                    onClick={() => {
                      authProvider.logout();
                    }}>
                    Wyloguj
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Menu
            show={showMenu}
            onHide={toggleMenu}
          />
          <div className={styles.titleContainer}>
            <h1 className={'text-center mb-0'}>{title}</h1>
          </div>
        </div>
        <Row className={`${styles.content} pb-3`}>
          <Outlet context={titleContext} />
        </Row>
        <ErrorToast />
      </Container>
    </React.StrictMode>
  );
}

function putUserInfoIntoClarity(userInfo: UserInfo) {
  clarityApi.identify(
    userInfo.email,
    undefined,
    undefined,
    `${userInfo.firstName} ${userInfo.lastName}`
  );
  clarityApi.setTag('email', userInfo.email);
  clarityApi.setTag('role', userInfo.role);
  clarityApi.increaseRecordingPriority('');
}

export function RootPage() {
  const errorContextProps = useInitErrorContext();
  if (!authProvider.isLoggedIn()) {
    authProvider.setInitialURL(window.location.pathname);
    return <Navigate to={NavigationPath.SIGN_IN} />;
  } else {
    const userInfo = authProvider.getUserInfo();
    putUserInfoIntoClarity(userInfo);
    return (
      <ErrorContextProvider errorContext={errorContextProps}>
        <UserContextProvider userInfo={userInfo}>
          <RootPageContent />
        </UserContextProvider>
      </ErrorContextProvider>
    );
  }
}
