import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModuleSchemaModel } from '../../data/module-model';
import { FileUpload } from '../file-upload/FileUpload';
import { ApiResult } from '../../api/http-client';
import { ItemType } from '../../data/item-model';
import { CategoryListSelect } from '../category/CategoryListSelect';
import { FormInput } from '../form/FormInput';
import { VariantListSelect } from '../variant/VariantListSelect';
import SubElementsTable from '../elements/SubElementsTable';
import { SubElementModel } from '../../data/element-model';

interface ModuleSchemaFormProps {
  moduleSchema?: ModuleSchemaModel;
  onSubmit: (
    moduleSchemaForm: ModuleSchemaFormModel
  ) => Promise<ApiResult<any>>;
  editMode: boolean;
}

interface ModuleSchemaFormModel {
  name: string;
  stockKeepingUnit: string;
  category: string;
  variant: string;
  imageId: string;
  elementList: SubElementModel[];
}

const ModuleSchemaForm = ({
  moduleSchema,
  onSubmit,
  editMode,
}: ModuleSchemaFormProps) => {
  const [name, setName] = useState(moduleSchema?.name ?? '');
  const [stockKeepingUnit, setStockKeepingUnit] = useState(
    moduleSchema?.stockKeepingUnit ?? ''
  );
  const [category, setCategory] = useState(moduleSchema?.category ?? '');
  const [variant, setVariant] = useState(moduleSchema?.variant ?? '');
  const [imageId, setImageId] = useState<string | undefined>(
    moduleSchema?.imageId
  );
  const [imageInvalid, setImageInvalid] = useState(false);
  const [validated, setValidated] = useState(false);
  const [elementList, setElementList] = useState(
    moduleSchema?.elementList ?? []
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    setImageInvalid(imageId === null || imageId === undefined);
    if (!form.checkValidity() || imageId === null || imageId === undefined) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    await onSubmit({
      name: name,
      stockKeepingUnit: stockKeepingUnit,
      category: category,
      variant: variant,
      imageId: imageId,
      elementList: elementList,
    });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}>
      <Row className={'justify-content-sm-center align-items-sm-center'}>
        <Col sm={5}>
          <FormInput
            value={name}
            setValue={setName}
            controlId={'name'}
            label={'Nazwa'}
            errorText={'Nazwa jest wymagana'}
          />
          <FormInput
            value={stockKeepingUnit}
            setValue={setStockKeepingUnit}
            controlId={'stockKeepingUnit'}
            label={'Stock Keeping Unit'}
            errorText={'SKU jest wymagany'}
          />
          <CategorySelect
            category={category}
            setCategory={setCategory}
          />
          <VariantSelect
            variant={variant}
            setVariant={setVariant}
          />
          <div className='d-flex justify-content-center pb-5'>
            <FileUpload
              size={300}
              onFileUpload={setImageId}
              isInvalid={imageInvalid}
              fileId={imageId}
              onFileDelete={() => setImageId(undefined)}
            />
          </div>
        </Col>
      </Row>
      <SubElementsTable
        subElementList={elementList}
        setSubElementList={setElementList}
        modificationElementId={moduleSchema?.id}
        itemType={ItemType.MODULE}
      />
      <Buttons />
    </Form>
  );
};

interface CategorySelectProps {
  category: string;
  setCategory: (value: string) => void;
}

const CategorySelect = ({ category, setCategory }: CategorySelectProps) => {
  return (
    <Form.Group
      className='pb-4'
      controlId='category'>
      <CategoryListSelect
        category={category}
        itemType={ItemType.MODULE}
        setCategory={setCategory}
      />
      <Form.Control.Feedback type='invalid'>
        Kategoria jest wymagana
      </Form.Control.Feedback>
    </Form.Group>
  );
};

interface VariantSelectProps {
  variant: string;
  setVariant: (value: string) => void;
}

const VariantSelect = ({ variant, setVariant }: VariantSelectProps) => {
  return (
    <Form.Group
      className='pb-5'
      controlId='category'>
      <VariantListSelect
        variant={variant}
        setVariant={setVariant}
      />
      <Form.Control.Feedback type='invalid'>
        Wariant jest wymagany
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const Buttons = () => {
  const navigate = useNavigate();

  return (
    <Row
      className='pt-2 justify-content-sm-center align-items-sm-center'
      style={{ backgroundColor: 'white', position: 'sticky', bottom: '0' }}>
      <Col sm={3} />
      <Col sm={2}>
        <Button
          variant='outline-primary'
          onClick={() => {
            navigate(-1);
          }}
          className='w-100 mb-2'>
          <FontAwesomeIcon icon={faXmark} />
          <span className='m-2'>Anuluj</span>
        </Button>
      </Col>
      <Col sm={2} />
      <Col sm={2}>
        <Button
          variant='primary'
          className='w-100 mb-2'
          type='submit'>
          <FontAwesomeIcon icon={faCheck} />
          <span className='m-2'>Zapisz</span>
        </Button>
      </Col>
      <Col sm={3} />
    </Row>
  );
};

export { ModuleSchemaForm };
export type { ModuleSchemaFormModel };
