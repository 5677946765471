import { useModules } from '../../../api/modules';
import React, { useState } from 'react';
import { useCategories } from '../../../api/categories';
import { ItemType } from '../../../data/item-model';
import { MobileListFilterButtons } from '../../../components/MobileListFilterButtons';
import { ActiveModuleFilters } from '../../../components/modules/ActiveModuleFilters';
import styles from '../Warehouse.module.scss';
import { CenteredSpinner } from '../../../components/CenteredSpinner';
import { Accordion, Col, Form, Image, Row } from 'react-bootstrap';
import { ModuleModel } from '../../../data/module-model';
import { useWarehouseModuleQuantityState } from '../../../components/table/field-states/WarehouseQuantityState';
import { getThumbnailUrl } from '../../../api/image/get-image';
import commonStyles from '../../Common.module.scss';
import { ImageZoomModal } from '../../../components/ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { EmptyListMessage } from '../../../components/EmptyListMessage';
import CustomPagination from '../../../components/CustomPagination';
import { QrCodeScanner } from '../../../components/QrCodeScanner';
import { QuantityChangeConfirmationModal } from '../../../components/QuantityChangeConfirmationModal';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { ModuleFiltersModal } from '../../../components/modules/ModuleFiltersModal';
import { useVariants } from '../../../api/variants';
import { NavigationPath } from '../../../data/constants';
import { useNavigate } from 'react-router-dom';
import { AccordionToggle } from '../../../components/AccordionToggle';

export function WarehouseModules() {
  const {
    moduleListResponse,
    isLoading,
    getModuleListRequest,
    setGetModuleListRequest,
    handlePaginationChange,
  } = useModules();

  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const { categoryList } = useCategories(ItemType.MODULE);
  const { variantList } = useVariants();
  const isWarehouseModulesListEmpty =
    moduleListResponse?.moduleList?.length === 0;

  const filterBySKU = (stockKeepingUnit: string) => {
    setShowScanner(false);
    setGetModuleListRequest({
      ...getModuleListRequest,
      stockKeepingUnit,
    });
  };

  return (
    <>
      {!showScanner && (
        <>
          <MobileListFilterButtons
            setShowFilters={setShowFilters}
            setShowScanner={setShowScanner}
          />
          <ActiveModuleFilters
            moduleRequest={getModuleListRequest}
            setModuleRequest={setGetModuleListRequest}
          />
          {isLoading && (
            <div className={styles.list}>
              <CenteredSpinner />
            </div>
          )}
          {!isLoading && !isWarehouseModulesListEmpty && (
            <div className={styles.list}>
              <Accordion
                alwaysOpen
                defaultActiveKey={activeKey}>
                {moduleListResponse.moduleList.map((module, index) => {
                  return (
                    <WarehouseModuleRow
                      key={module.stockKeepingUnit}
                      module={module}
                      index={index}
                      refetch={() =>
                        setGetModuleListRequest({ ...getModuleListRequest })
                      }
                      setActiveKey={setActiveKey}
                    />
                  );
                })}
              </Accordion>
            </div>
          )}
          {!isLoading && isWarehouseModulesListEmpty && (
            <EmptyListMessage message={'Brak modułów w magazynie.'} />
          )}
          {!isWarehouseModulesListEmpty && (
            <CustomPagination
              pageNumber={moduleListResponse.page.pageNumber + 1}
              elementsPerPage={moduleListResponse.page.pageSize}
              items={moduleListResponse.moduleList}
              totalPages={moduleListResponse.page.pageCount}
              onPaginationParamsChange={(pageNumber, elementsPerPage) =>
                handlePaginationChange(pageNumber, elementsPerPage)
              }
              disablePageSizeChange
            />
          )}
          <ModuleFiltersModal
            show={showFilters}
            setShow={setShowFilters}
            moduleListRequest={getModuleListRequest}
            setModuleListRequest={setGetModuleListRequest}
            moduleCategories={categoryList}
            moduleVariants={variantList}
          />
        </>
      )}
      {showScanner && (
        <QrCodeScanner
          onClose={() => setShowScanner(false)}
          onDecode={text => filterBySKU(text)}
        />
      )}
    </>
  );
}

interface WarehouseModuleRowProps {
  module: ModuleModel;
  index: number;
  refetch: () => void;
  setActiveKey: (key: string) => void;
}

function WarehouseModuleRow(props: WarehouseModuleRowProps) {
  const { module, index, refetch, setActiveKey } = props;

  const [newModuleQuantity, setNewModuleQuantity] = useState(
    module.warehouseQuantity.toString()
  );
  const [editMode, setEditMode] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const navigate = useNavigate();

  const {
    showQuantityConfirmationModal,
    setShowQuantityConfirmationModal,
    callChangeElementQuantity,
  } = useWarehouseModuleQuantityState(parseInt(newModuleQuantity));

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleConfirmationClick = () => {
    setShowQuantityConfirmationModal(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewModuleQuantity(module.warehouseQuantity.toString());
  };

  const handleQuantityChange = (event: any) => {
    setNewModuleQuantity(
      event.target.validity.valid ? event.target.value : '0'
    );
  };

  const handleWarehouseModuleModification = () => {
    callChangeElementQuantity({
      id: module.id,
      newQuantity: parseInt(newModuleQuantity),
      oldQuantity: module.warehouseQuantity,
    }).then((response: any) => {
      if (!response.error) {
        setEditMode(false);
        setShowQuantityConfirmationModal(false);
        refetch();
        setActiveKey(index.toString());
      }
    });
  };

  const handleCraftModuleClick = (moduleId: string) => {
    navigate(`${NavigationPath.CRAFT_MODULE}/${moduleId}`);
  };

  return (
    <>
      <Row className={'border-bottom border-1 pe-4 pt-3 pb-3'}>
        <Col xs={3}>
          <Image
            src={getThumbnailUrl(module.imageId)}
            fluid
            className={`${commonStyles.thumbnailImage}`}
            onClick={() => setShowImageModal(true)}
          />
          <ImageZoomModal
            showImageModal={showImageModal}
            setShowImageModal={setShowImageModal}
            imageId={module.imageId}
          />
        </Col>
        <Col xs={5}>
          <div className={'text-break'}>{module.name}</div>
          <div className={`text-break ${commonStyles.secondaryText}`}>
            {module.stockKeepingUnit}
          </div>
        </Col>
        <Col xs={3}>
          <div>Ilość</div>
          <div className={commonStyles.secondaryText}>
            {module.warehouseQuantity}
          </div>
        </Col>
        <Col
          xs={1}
          className={'d-flex justify-content-center'}>
          <AccordionToggle eventKey={index.toString()} />
        </Col>
        <Accordion.Collapse eventKey={index.toString()}>
          <>
            <Row className={'mt-3'}>
              <Col xs={5}>
                <div className={commonStyles.secondaryText}>Nazwa</div>
                <div className={'text-break'}>{module.name}</div>
              </Col>
              <Col xs={5}>
                <div className={commonStyles.secondaryText}>SKU</div>
                <div className={'text-break'}>{module.stockKeepingUnit}</div>
              </Col>
            </Row>
            <Row className={'mt-3 pe-2'}>
              <Col xs={3}>
                <div className={commonStyles.secondaryText}>Kategoria</div>
                <div className={'text-break'}>{module.category}</div>
              </Col>
              <Col xs={3}>
                <div className={commonStyles.secondaryText}>Wariant</div>
                <div className={'text-break'}>{module.variant}</div>
              </Col>
              <Col xs={3}>
                <div className={commonStyles.secondaryText}>Ilość</div>
                <div className={'text-break'}>
                  {!editMode && module.warehouseQuantity}
                  {editMode && (
                    <Form.Control
                      type='number'
                      defaultValue={newModuleQuantity ?? 0}
                      min='0'
                      onChange={handleQuantityChange}
                    />
                  )}
                </div>
              </Col>
              <Col xs={3}>
                {editMode && (
                  <Row className={'justify-content-around'}>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        size={'2x'}
                        className={`${commonStyles.iconGreen}`}
                        onClick={() => handleConfirmationClick()}
                      />
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faXmark}
                        size={'2x'}
                        className={`${commonStyles.iconRed}`}
                        onClick={() => handleCancelClick()}
                      />
                    </Col>
                  </Row>
                )}
                {!editMode && (
                  <Row className={'justify-content-around'}>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        size={'2x'}
                        className={`${commonStyles.iconPrimary}`}
                        onClick={() => handleCraftModuleClick(module.id)}
                      />
                    </Col>
                    <Col xs={2}>
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        size={'2x'}
                        className={`${commonStyles.iconPrimary}`}
                        onClick={() => handleEditClick()}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>
        </Accordion.Collapse>
      </Row>
      <QuantityChangeConfirmationModal
        show={showQuantityConfirmationModal}
        setShow={setShowQuantityConfirmationModal}
        title={'Zaktualizowanie ilości modułów'}
        bodyText={`Czy na pewno chcesz ustawić stan magazynu dla modułu 
          ${module.name} na ilość ${newModuleQuantity}?`}
        refetch={refetch}
        handleQuantityChange={handleWarehouseModuleModification}
      />
    </>
  );
}
