import React, { useContext, useState } from 'react';
import styles from './LoginCard.module.scss';
import commonStyles from '../Common.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Col, Dropdown, Row } from 'react-bootstrap';
import { UserContext } from '../../context/UserContextProvider';
import { authProvider } from '../../api/auth/auth';

interface CustomToggleProps {
  show: boolean;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {};
}

const IconToggle = React.forwardRef<
  HTMLDivElement,
  {
    show: boolean;
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {};
  }
>((props: CustomToggleProps, ref: React.LegacyRef<HTMLDivElement>) => {
  const isOpen = props.show;
  return (
    <div
      className={`${isOpen ? styles.customDropdown : styles.border} p-3`}
      ref={ref}>
      <Row className='align-items-center'>
        <Col sm={'auto'}>
          <FontAwesomeIcon
            icon={faUser}
            size={'xl'}
            className={`${styles.icon}`}
          />
        </Col>
        <Col sm={'auto'}>{props.children}</Col>
        <Col sm={'auto'}>
          <FontAwesomeIcon
            icon={isOpen ? faAngleUp : faAngleDown}
            className={`${commonStyles.iconPrimary}`}
            size='xl'
            onClick={(e: React.MouseEvent<SVGSVGElement>) => {
              e.preventDefault();
              props.onClick(e);
            }}
          />
        </Col>
      </Row>
    </div>
  );
});

function LoginCard() {
  const user = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      show={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      className={'d-inline-block text-break'}
      as={ButtonGroup}>
      <Dropdown.Toggle
        show={isOpen}
        as={IconToggle}>
        {`${user.firstName} ${user.lastName}`}
      </Dropdown.Toggle>
      <Dropdown.Menu className={`w-100 pt-0 pb-0 ${styles.customDropdown}`}>
        <Dropdown.Item
          className={`pt-3 pb-3 ${commonStyles.customHover}`}
          eventKey='1'
          onClick={() => {
            authProvider.logout();
          }}>
          Wyloguj
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export { LoginCard };
