import { BaseRendererType, CustomTableState } from '../CustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import React from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import commonStyles from '../../../desktop-view/Common.module.scss';

export interface ExpandableActionProps {
  expandCondition: (object: any) => boolean;
}

export function ExpandableActionRenderer(props: BaseRendererType): JSX.Element {
  const expandableProps: ExpandableActionProps = props.tableState.externalProps;
  const tableState: CustomTableState = props.tableState;

  const changeExpandState = () => {
    tableState.setObject({
      ...tableState.object,
      isExpanded: !tableState.object.isExpanded,
    });
  };
  return (
    <>
      {expandableProps.expandCondition(tableState.object) && (
        <FontAwesomeIcon
          icon={tableState.object.isExpanded ? faChevronUp : faChevronDown}
          size={'xl'}
          className={`${commonStyles.iconPrimary} me-3`}
          onClick={changeExpandState}
        />
      )}
    </>
  );
}
