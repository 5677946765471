import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import styles from './Crafting.module.scss';
import { debounce } from 'lodash';
import { getThumbnailUrl } from '../../api/image/get-image';
import commonStyles from '../../desktop-view/Common.module.scss';
import { ImageZoomModal } from '../ImageZoomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import {
  ModuleCraftingPreview,
  ModuleSubItemCraftingPreview,
} from '../../api/elements/preview-module-crafting';
import { useImageModal } from '../../hook/useImageModal';

export const PreviewCraftingModuleTable = (props: {
  moduleList: ModuleCraftingPreview[];
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange?: (quantity: number) => void;
  isCraftingRoot: boolean;
  elementIdToScroll?: string;
  onGoToEnd?: () => void;
}) => {
  const {
    moduleList,
    onGoToClick,
    onQuantityChange,
    isCraftingRoot,
    elementIdToScroll,
    onGoToEnd,
  } = props;

  const refToScroll = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementIdToScroll && refToScroll.current) {
      refToScroll.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [elementIdToScroll]);

  return (
    <>
      <TableHeader />
      {isCraftingRoot && moduleList.length === 1 ? (
        <ModuleCraftingRoot
          modulePreview={moduleList[0]}
          onGoToClick={onGoToClick}
          onQuantityChange={onQuantityChange!!}
        />
      ) : (
        moduleList.map(elementSet => {
          if (elementSet.id === elementIdToScroll) {
            return (
              <ModuleRow
                ref={refToScroll}
                modulePreview={elementSet}
                onGoToClick={onGoToClick}
                onGoToEnd={onGoToEnd}
              />
            );
          } else {
            return (
              <ModuleRow
                modulePreview={elementSet}
                onGoToClick={onGoToClick}
              />
            );
          }
        })
      )}
    </>
  );
};

const TableHeader = () => {
  return (
    <Row className={'border-bottom border-3 pb-2'}>
      <Col
        sm={'auto'}
        className={'h-100 d-flex justify-content-end'}>
        <div className={`vr ${styles.invisibleVerticalLine}`}></div>
      </Col>
      <Col></Col>
      <Col>Nazwa</Col>
      <Col>SKU</Col>
      <Col>Kategoria</Col>
      <Col>Ilość posiadana</Col>
      <Col>Ilość potrzebna</Col>
      <Col>Ilość końcowa</Col>
      <Col></Col>
    </Row>
  );
};

const ModuleCraftingRoot = (props: {
  modulePreview: ModuleCraftingPreview;
  onGoToClick: (id: string, activeTab: string) => void;
  onQuantityChange: (quantity: number) => void;
}) => {
  const { modulePreview, onGoToClick, onQuantityChange } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();
  const [quantity, setQuantity] = useState<number>(
    modulePreview.requiredQuantity
  );
  const setCraftQuantityDebounced = useMemo(() => {
    return debounce(onQuantityChange, 500);
  }, [onQuantityChange]);

  const handleCraftQuantityChange = useCallback(
    (event: any) => {
      const newCraftQuantity =
        event.target.value !== '' && event.target.validity.valid
          ? parseInt(event.target.value)
          : 0;
      setQuantity(newCraftQuantity);
    },
    [setQuantity]
  );

  useEffect(() => {
    setCraftQuantityDebounced(quantity);
  }, [quantity, setCraftQuantityDebounced]);

  return (
    <>
      <Row className={'align-items-center pt-2 pb-2 border-bottom border-1'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.invisibleVerticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center'}>
          <Image
            src={getThumbnailUrl(modulePreview.imageId)}
            fluid
            className={commonStyles.zoomableImage}
            onClick={() => handleImageClick(modulePreview.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>
        <Col className={'text-break'}>{modulePreview.name}</Col>
        <Col className={'text-break'}>{modulePreview.stockKeepingUnit}</Col>
        <Col className={'text-break'}>{modulePreview.category}</Col>
        <Col className={'text-break'}>{modulePreview.oldQuantity}</Col>
        <Col className={'text-break'}>
          <Form.Control
            className='w-50'
            type='number'
            defaultValue={quantity}
            min='0'
            onChange={handleCraftQuantityChange}
          />
        </Col>
        <Col className={'text-break'}>
          <td>{modulePreview.newQuantity}</td>
        </Col>
        <Col></Col>

        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      {modulePreview.subiItemList.map(it => (
        <SubItemRow
          subItem={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
};

const ModuleRow = React.forwardRef<
  HTMLDivElement,
  {
    modulePreview: ModuleCraftingPreview;
    onGoToClick: (id: string, activeTab: string) => void;
    onGoToEnd?: (() => void) | undefined;
  }
>((props, ref) => {
  const { modulePreview, onGoToClick, onGoToEnd } = props;

  const { showImageModal, setShowImageModal, selectedImage, handleImageClick } =
    useImageModal();

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (ref) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
        onGoToEnd!!();
      }, 1000); // Remove highlight after 1 second
    }
  }, [ref, onGoToEnd]);

  return (
    <>
      <Row
        ref={ref}
        className={`align-items-center pt-2 pb-2 border-bottom border-1 ${isHighlighted ? styles.highlight : ''}`}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.invisibleVerticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center'}>
          <Image
            src={getThumbnailUrl(modulePreview.imageId)}
            fluid
            className={commonStyles.zoomableImage}
            onClick={() => handleImageClick(modulePreview.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>
        <Col className={'text-break'}>{modulePreview.name}</Col>
        <Col className={'text-break'}>{modulePreview.stockKeepingUnit}</Col>
        <Col className={'text-break'}>{modulePreview.category}</Col>
        <Col className={'text-break'}>
          {modulePreview.oldQuantity}{' '}
          {modulePreview.craftedQuantity !== 0 && (
            <label className={styles.greenText}>
              +{modulePreview.craftedQuantity}
            </label>
          )}
        </Col>
        <Col className={'text-break'}>{modulePreview.requiredQuantity}</Col>
        <Col className={'text-break'}>
          <td>{modulePreview.newQuantity}</td>
        </Col>
        <Col></Col>

        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      {modulePreview.subiItemList.map(it => (
        <SubItemRow
          subItem={it}
          handleGoToButtonClick={onGoToClick}
        />
      ))}
    </>
  );
});

const SubItemRow = (props: {
  subItem: ModuleSubItemCraftingPreview;
  handleGoToButtonClick: (id: string, activeTab: string) => void;
}) => {
  const { subItem, handleGoToButtonClick } = props;
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setShowImageModal(true);
  };
  return (
    <>
      <Row className={'align-items-center'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </Col>
        <Col className={'d-flex justify-content-center pt-2 pb-2'}>
          <Image
            src={getThumbnailUrl(subItem.imageId)}
            fluid
            className={`${commonStyles.zoomableImage}`}
            onClick={() => handleImageClick(subItem.imageId)}
            style={{ width: 50, height: 50 }}
          />
        </Col>

        <Col className={'text-break'}>{subItem.name}</Col>
        <Col className={'text-break'}>{subItem.stockKeepingUnit}</Col>
        <Col className={'text-break'}>{subItem.category}</Col>
        <Col className={'text-break'}>
          {subItem.oldQuantity}{' '}
          {subItem.craftedQuantity !== 0 && (
            <label className={styles.greenText}>
              +{subItem.craftedQuantity}
            </label>
          )}
        </Col>
        <Col className={'text-break'}>{subItem.requiredQuantity}</Col>
        <Col
          className={`text-break ${subItem.newQuantity >= 0 ? styles.greenText : styles.redText}`}>
          {subItem.newQuantity}
        </Col>
        <Col>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            size={'xl'}
            className={`${commonStyles.iconPrimary} me-3`}
            onClick={() =>
              handleGoToButtonClick(
                subItem.id,
                subItem.isElementSet ? 'elementSets' : 'elements'
              )
            }
          />
        </Col>
        <ImageZoomModal
          showImageModal={showImageModal}
          setShowImageModal={setShowImageModal}
          imageId={selectedImage}
        />
      </Row>
      <Row className={'align-items-center'}>
        <Col
          sm={'auto'}
          className={'h-100 d-flex justify-content-end'}>
          <div className={`vr ${styles.verticalLine}`}></div>
        </Col>
        <Col className={styles.horizontalLine}></Col>
      </Row>
    </>
  );
};
